<template>
  <div class="container">
    <img class="avatar" :src="avatar">
    <span class="nickname">{{nickname}}</span>
  </div>
</template>

<script>
export default {
  name: "ticket",
  data() {
    return {
      nickname: '',
      avatar: '',
    };
  },
  created() {
    let { nickname, avatar } = this.$route.query;
    this.nickname = nickname;
    this.avatar = avatar;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  position: relative;
  width: 100vw;
  height: 80vw;
  background: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018615e5-d067-fbdc-fb8a-e080a54525d0?x-oss-process=style/origin) 
  no-repeat center center / cover;

  .avatar {
    position: absolute;
    left: px2rem(43.34 * $multipleCount);
    top: px2rem(192 * $multipleCount);
    width: px2rem(36 * $multipleCount);
    height: px2rem(36 * $multipleCount);
    border: px2rem(2.6 * $multipleCount) solid #222;
    border-radius: 50%;
  }

  .nickname {
    @include ellipsis;
    position: absolute;
    left: px2rem(94 * $multipleCount);
    top: px2rem(195 * $multipleCount);
    display: inline-block;
    width: px2rem(210 * $multipleCount);
    font-size: px2rem(24 * $multipleCount);
    height: px2rem(36 * $multipleCount);
    line-height: px2rem(36 * $multipleCount);
    font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
  }
}
</style>
